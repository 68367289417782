@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

:root {
  --font-size: 2rem;
  --font: Arial, Helvetica, sans-serif;
  --font-styled: Roboto;
  --main-bg-color: lightblue;
  --clr-primary: lightblue;
  --clr-light: #f4f4f4;
  --clr-dark: #333;
  --clr-warning: rgb(99, 36, 36);
  --box-shadow-blur: 5px;
  --box-shadow-color: grey;
  --box-shadow-h-offset: 2px;
  --box-shadow-v-offset: 2px;
  --box-padding: 1rem;
  --grid-gap: 1rem;
  --media-ipad-width: 500px;
}

*,
*::before,
*::after {
  font-family: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

a {
  color: #262626;
  text-decoration: none;
}

ul {
  list-style: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #000;
  font-size: 15px;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  padding-top: 40px;
  padding: 1em;
  background-color: var(--clr-primary);
  */
}

.shadow {
  box-shadow: var(--box-shadow-h-offset) var(--box-shadow-v-offset)
    var(--box-shadow-blur) var(--box-shadow-color);
}

img {
  max-width: 100%;
  width: auto;
}

.fade-in {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.fade-in.appear {
  opacity: 1;
}

section {
  padding: 3rem 0;
}

@media (min-width: 576px) {
  section {
      padding: 4.5rem 0;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #f8f9fa;
  height: 100vh;
}

.main-container {
  height: 100vh;
}
